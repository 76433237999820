<template>
	<v-card-text class="pa-0">
		<v-card class="pa-8" flat height="100%">
			<v-row no-gutters align="center" class="mt-5">
				<v-flex xs12>
					<PageTitle
						:subheading="$t('terms_and_conditions')"
						:type="'style1'"
					/>
				</v-flex>
				<v-flex xs12 class="overflow-y-auto" width="50%">
					<span class="text-justify text-wrap text-h6 text--opacity-75"
						>{{ $t('terms_message') }}
					</span>
				</v-flex>
				<v-flex xs12 text-left>
					<v-checkbox 
						:value="setupData.readTerm" 
						:rules="rules.required"
						@change="$emit('update:read_accept', $event)"
					>
						<template #label>
							<div
								class="text-subtitle-2 text--opacity-75"
								v-html="$t('terms_and_conditions_label')"
							/>
						</template>
					</v-checkbox>
				</v-flex>
				<v-flex xs12 text-left>
					<v-divider />
				</v-flex>
				<v-flex xs12 text-left class="mt-5">
					<span class="text-subtitle-1" v-html="$t('agree_sms_msg_heading')" />
					<v-checkbox
            hide-details="auto" 
            :value="setupData.smsTerm" 
						:rules="rules.required"
						@change="$emit('update:agree_sms', $event)">
						<template #label>
							<div
								class="text-subtitle-2 text--opacity-75"
								v-html="$t('agree_sms_msg_label')"
							/>
						</template>
					</v-checkbox>
				</v-flex>
			</v-row>
		</v-card>
	</v-card-text>
</template>
<script>
import PageTitle from '@/components/talent-setup/PageTitle.vue'

// Utils
import { rules } from '@/utils/validation.js'

export default {
	props: { 
    setupData: { 
      type: Object, 
      default: () => {},
    },
  },
	data() {
		return {
			rules,
		}
	},
}
</script>
