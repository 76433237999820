<template>
  <v-card-text class="pa-0">
    <v-card
      color="grey lighten-1"
      class="pa-8"
      flat
      height="100%"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="5"
          class="pa-0"
        >
          <PageTitle
            :heading="$t('choose_password')"
            :type="'style1'"
          />
          <!-- <v-form v-model="valid"> -->
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-checkbox-marked-circle-outline' : ''"
              class="password-error"
              data-cy="Password"
              hide-details="auto"
              :placeholder="$t('password')"
              outlined
              required
              type="password"
              :rules="rules.password"
              height="64px"
              @keyup="$emit('update:password', password)"
              @click="show = true"
            />
          <!-- </v-form> -->
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import { rules } from "@/utils/validation.js";
import PageTitle from "@/components/talent-setup/PageTitle.vue";
export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      rules,
      password: null,
      valid: false,
      show: false,
    };
  },
};
</script>
<style lang="scss">
.v-application .primary--text {
  color: $mediumseagreen !important;
}
.password-error{
  .v-messages__message {
    color: #ff0000 !important;
    opacity: 1 !important;
    font-weight: 600;
  }
  .error--text {
    opacity: 1 !important;
  }
}
</style>
