<template>
  <v-card-text
    color="light_grey"
    class="pa-0"
  >
    <PageTitle
      :heading="$t('personalize_your_with_url')" 
        :subheading="$t('personalize_your_with_url_subtitle')"
    />
    <v-card
      color="grey lighten-1"
      :class="$device.mobile?'pa-1':'pa-8'"
      flat
      height="100%"
    >
      <v-card-text class="text-center px-sm-0">
        <AlertBox
          :message="error"
          class="ma-1 mb-2"
        />
        <v-row
          no-gutters
        >
          <v-col
            md="12"
            sm="12"
            xs="12"
          >
            <v-row no-gutters>
              <v-flex
                class="d-inline div-style web-url pl-3 pl-sm-1 primary text-sm-h6 text-md-h4 mobile-url"
                align-justify-content="center"
                xs5
                md4
              >
                <span v-if="!$device.mobile">{{ $t('with_anyone.com') }}</span>
                <span v-else>meetwithanyone<br />.com/</span>
              </v-flex>
              <v-flex
                class="d-inline"
                xs4
                md5
              >
                <v-text-field
                  ref="copyName"
                  v-model="username"
                  outlined
                  required
                  :rules="rules.username"
                  @input="checkOnInactivity"
                />
              </v-flex>
              <v-flex
                class="d-inline text-left ml-md-3"
                xs2checkOnInactivity
                md2checkOnInactivity
              >
                <v-btn
                  color="white"
                  height="56"
                  min-width="56"
                  width="56"
                  class="pa-md-7 ml-sm-2"
                  @click="copyContent()"
                >
                  <img
                    target="_blank"
                    width="25"
                    height="25"
                    src="@/assets/images/copy-content.svg"
                    alt="copy-content"
                  >
                </v-btn>
              </v-flex>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mr-0">
      <v-flex class="d-flex justify-end mb-6">
        <v-btn
          :class="disabled ? 'v-btn--disabled' : ''"
          color="primary mt-md-10 mt-sm-8 text-center px-16"
          :to="`talent/availability`"
        >
          {{ $t("visit_profile") }}
        </v-btn>
      </v-flex>
    </v-row>
  </v-card-text>
</template>
<script>
import PageTitle  from "@/components/talent-setup/PageTitle.vue";
import { mapGetters } from 'vuex'

// graphql
import { UPDATE_USER_NAME } from "@/graphql/account";
// utils
import { rules } from "@/utils/validation";

export default {
  components: { 
    PageTitle,
  },
  data(){
    return {
      username: null,
      error: null,
      rules,
      disabled: false,
      timer: {}
    }
  },
  computed: {
    ...mapGetters([ 'user', 'jwt' ]),
    isMdAndAbove() {
      return [ "md", "lg", "xl" ].includes(this.$vuetify.breakpoint.name);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch:{
    username(val){
      if(!val){
        this.disabled = true
        return;
      }
      this.disabled = false;
    },
  },
  created(){
    this.username = this.user.username;
  },
  methods:{
    copyContent(){
      const el = document.createElement('textarea');
      el.value = window.location.origin+"/talent/profile/"+this.$refs.copyName.value;                                
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
    },
    async updateUserName(){
      this.error = null;
      if (this.username !== "") {
        this.disabled = true;
        try {
          const { data: { updateUsername } } = await this.$apollo.mutate({
            mutation: UPDATE_USER_NAME,
            variables:{
              userData: {
                email: this.user.email,
                accesstoken: this.jwt,
                username: this.username,
              },
            },
          });

          // Set User Intro in vuex
          if (updateUsername.success) {
            this.$store.commit("setUsername", this.username);
          } else {
            this.error = this.$t(updateUsername.error);
          }
          this.disabled = false;

        } catch (e) {
          this.error = e
          this.loading = false
          this.disabled = false;
          console.error(e); // eslint-disable-line
        }
      }
    },
    checkOnInactivity() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => this.updateUserName(), 2000);
      
    },
  },
};
</script>
<style lang="scss">
.div-style{
    background: rgba(248, 248, 248, 0.2);
    height: 56px;
    border-radius: 4px;
    line-height: 56px !important
}
.profile-btn{
   box-shadow: 0px 38px 35px gainsboro;
   width: 60%;
}
@media only screen and (max-width: 600px) {
  .profile-btn{
   box-shadow: 0px 38px 35px gainsboro;
   width: 93%;
  }
  .mobile-url {
    line-height: 24px !important;
    padding-top: 6px;
  }
}
.web-url{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
}
</style>