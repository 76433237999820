<template>
  <v-card-text class="pa-8">
    <v-input
      class="mb-3 error-text"
      hidden
      :rules="[
        () =>
          Boolean(selectedTags && selectedTags.length > 2) ||
          'Select at least three.',
      ]"
    />
    <v-card class="mb-10 overflow-y-auto" flat height="450">
      <v-row no-gutters>
        <v-col
          v-for="(accountTag, n) in accountTags"
          :key="n"
          cols="12"
          sm="12"
          xs="12"
          md="3"
          class="px-4"
        >
          <v-checkbox
            v-model="selectedTags"
            :label="accountTag"
            :value="accountTag"
            hide-details
            :class="{ 'white-label': selectedTags.includes(accountTag) }"
            :color="selectedTags.includes(accountTag) ? 'white' : 'primary'"
            :background-color="
              selectedTags.includes(accountTag) ? 'primary' : null
            "
            on-icon="mdi-checkbox-marked-outline"
            class="rounded-4 pa-1"
            @change="$emit('update:selected-tags', selectedTags)"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
// Utils
import { GET_TALENT_AREA } from '@/graphql/master'

export default {
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      accountTags: [],
      selectedTags: [],
    }
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.selectedTags = [...val.talentarea]
      },
    },
  },
  async mounted() {
    const {
      data: { getTalentArea },
    } = await this.$apollo.query({
      query: GET_TALENT_AREA,
      variables: {
        topLevel: true,
      },
    })
    this.accountTags = getTalentArea
      .filter(x => x.level !== 1)
      .map(x => x.name)
      .sort()
  },
}
</script>
<style lang="scss">
.error-text {
  color: red !important;
}
</style>
