<template>
  <v-card-text class="pa-0">
    <v-card
      color="grey lighten-1"
      height="100%"
      class="pa-8"
      flat
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          class="pa-0"
        >
          <PageTitle
            :heading="$t('name')"
            :type="'style1'"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
          xs="12"
          md="4"
          class="pa-0 "
        >
          <v-text-field
            class="input-error"
            :value="setupData.firstName"
            data-cy="First Name"
            :placeholder="$t('first_name')"
            outlined
            required
            :rules="rules.name"
            hide-details="auto"
            height="64px"
            @input="$emit('update:firstName', $event.trim());"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
          xs="12"
          md="5"
          class="pa-0 ml-sm-10  pl-md-8"
        >
          <v-text-field
            class="input-error"
            :value="setupData.lastName"
            data-cy="Last Name"
            :placeholder="$t('last_name')"
            outlined
            required
            :rules="rules.name"
            hide-details="auto"
            height="64px"
            @input="$emit('update:lastName', $event.trim());"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import { rules } from '@/utils/validation.js'
import PageTitle  from "@/components/talent-setup/PageTitle.vue";
export default {
  components: { 
    PageTitle,
  },
  props: { 
    setupData: { 
      type: Object, 
      default: () => {},
    },
  },
  data() {
    return {
      rules,
    };
  },
};
</script>
<style lang="scss">
.v-application .error--text {
  color: black !important;
  opacity: 0.5;
  font-weight: 600;
}
</style>
