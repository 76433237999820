<template>
  <v-card-text class="pa-0">
    <v-card flat height="100%" class="pa-8">
      <v-row no-gutters align="center">
        <v-flex xs12>
          <PageTitle
            :heading="$t('profile_photo')"
            :subheading="$t('profile_photo_message')"
            :type="'style1'"
          />
        </v-flex>
        <v-flex xs12>
          <AlertBox :message="picFileTypeError" class="ma-1 mb-2" />
        </v-flex>
        <v-flex xs12 md4>
          <v-card
            v-if="!cameraStart"
            flat
            rounded="lg"
            color="dark_grey"
            height="240"
            width="230"
            class="pa-16"
          >
            <v-img
              height="100px"
              width="100px"
              :src="
                profilePicUrl
                  ? profilePicUrl
                  : require('@/assets/images/defaultProfileImg.svg')
              "
            />
          </v-card>
          <vue-web-cam
            class="capture-pic"
            v-show="cameraStart"
            ref="webcam"
            width="230"
            height="240"
            :device-id="deviceId"
            @error="onError"
            @cameras="onCameras"
          />
          <v-btn
            v-if="cameraStart"
            class="d-block no-shadow"
            depressed
            color="primary"
            @click="onCapture()"
          >
            {{ $t('capture_photo') }}
          </v-btn>
          <v-btn
            v-if="cameraStart"
            class="no-shadow mt-sm-5"
            depressed
            color="primary"
            @click="onTransfer()"
          >
            {{ $t('upload_profile') }}
          </v-btn>
        </v-flex>
        <v-flex xs12 md2>
          <input
            ref="file"
            hidden
            type="file"
            accept=".jpg, .jpeg, .png"
            @change="handleFileImageUpload()"
          />
          <v-btn
            v-if="!cameraStart"
            class="no-shadow mt-sm-5"
            depressed
            color="primary"
            @click="onUpload()"
          >
            {{ $t('upload_profile') }}
          </v-btn>
          <v-btn
            v-if="!cameraStart"
            plain
            class="no-shadow mt-md-5 mt-sm-5 mt-md-4"
            @click="onStart()"
          >
            {{ $t('take_photo') }}
          </v-btn>
        </v-flex>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import PageTitle from '@/components/talent-setup/PageTitle.vue'
import { mapGetters } from 'vuex'
//utils
import i18n from '@/utils/i18n'
import { WebCam } from 'vue-web-cam'
import { PROFILE } from '@/graphql/talent'

export default {
  components: {
    PageTitle,
    'vue-web-cam': WebCam,
  },
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profilePicUrl: null,
      profilePic: null,
      picFileTypeError: null,
      deviceId: '',
      cameras: [],
      cameraStart: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.profilePicUrl = val.imageFileUrl
      },
    },
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    async getProfile() {
      this.loading = true
      const {
        data: { getBookingPageInfo },
      } = await this.$apollo.query({
        query: PROFILE,
        variables: {
          username: this.user.username,
        },
      })
      if (getBookingPageInfo.success) {
        this.loading = false
        this.profilePicUrl = getBookingPageInfo.profile.user.profileImg
      }
    },
    onCameras(cameras) {
      this.cameras = cameras
    },
    onTransfer() {
      this.onStop()
      this.onUpload()
    },
    onUpload() {
      this.$refs.file.click()
    },
    onCapture() {
      var captureImage = this.$refs.webcam.capture()
      this.onStop()
      this.showRemoveAvatarButton = true
      this.profilePicUrl = captureImage
      this.$emit('update:image-file', null)
      this.$emit('update:image-file-url', this.profilePicUrl.split(',')[1])
      this.$emit('update:image-base', this.profilePicUrl.split(',')[1])
    },
    onError(error) {
      console.log('On Error Event', error)
    },
    onStop() {
      this.$refs.webcam.stop()
      this.cameraStart = false
    },
    onStart() {
      if (this.cameras[0] == undefined) {
        this.showError('camera_not_found')
        return
      }
      this.deviceId = this.cameras[0].deviceId
      this.cameraStart = true
      this.$refs.webcam.start()
      this.$emit('update:image-upload-type', 'base64')
    },
    handleFileImageUpload() {
      this.picFileTypeError = null

      /*
        Set the local file variable to what the user has selected.
      */
      this.profilePic = this.$refs.file.files[0]
      this.$refs.file.value = null

      /*
        Check to see if the file is not empty.
        Ensure the file is an image file.
      */
      if (this.profilePic && /\.(jpe?g|png)$/i.test(this.profilePic.name)) {
        if (this.profilePic.size > 6000000) {
          this.showError('file_size_msg')
          return
        }

        /*
          Initialize a File Reader object
        */
        const reader = new FileReader()

        /*
          Add an event listener to the reader that when the file
          has been loaded, we flag the show preview as true and set the
          image to be what was read from the reader.
        */
        reader.addEventListener(
          'load',
          () => {
            this.showRemoveAvatarButton = true
            this.profilePicUrl = reader.result
            this.$emit('update:image-file-url', this.profilePicUrl)
            this.$emit('update:image-upload-type', 'object')
          },
          false
        )

        /*
        Fire the readAsDataURL method which will read the file in and
        upon completion fire a 'load' event which we will listen to and
        display the image in the preview.
        */
        reader.readAsDataURL(this.profilePic)
        this.$emit('update:image-base', null)
        this.$emit('update:image-file', this.profilePic)
      } else {
        this.showError('invalid_file_type')
      }
    },

    showError(message) {
      this.picFileTypeError = i18n.t(message)
      this.$refs.file.value = ''
      this.$nextTick(() => {
        this.timeOut = setTimeout(() => {
          this.picFileTypeError = null
        }, 3000)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  video {
    transform: scaleX(-1);
  }
</style>
