<template>
  <v-card-text class="pa-0">
    <v-card
      color="grey lighten-1"
      class="pa-8"
      height="100%"
      flat
    >
      <PageTitle
        :heading="$t('email_and_phone')"
        :type="'style1'"
      />
      <!-- <h3
        class="mt-5 mb-5 pb-5 ml-5 pt-5 mb-3 font-weight-normal"
      >{{$t('email_and_phone')}}</h3> -->
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
        >
          <v-text-field
            class="input-error"
            :value="setupData.email"
            data-cy="Email"
            :placeholder="$t('email_address')"
            outlined
            required
            :rules="rules.email"
            :error-messages="emailError"
            hide-details="auto"
            height="64px"
            @input="checkAvailability($event), $emit('update:email', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="5"
          class="pa-0 ml-sm-0 pl-md-4 ml-5 mt-sm-8 mt-md-0 phonenum"
        >
          <VuePhoneNumberInput
            id="phoneNumber" 
            v-model="phoneNumber"
            :default-country-code="phoneCode" 
            :required="true"
            color="#F7F8FB"
            @update="onUpdate"
          />
          <div
            v-if="phoneNumberError"
            class="v-text-field__details pt-6"
          >
            <div
              class="v-messages theme--light error--text"
              role="alert"
            >
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import PageTitle  from "@/components/talent-setup/PageTitle.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import { countryCode } from "@/utils/static";

import 'vue-phone-number-input/dist/vue-phone-number-input.css';


// utils
import { rules } from '@/utils/validation.js'
import i18n  from '@/utils/i18n.js'

// graphql
import { AVAILABLE } from "@/graphql/account.js";

export default {
  components: { 
    PageTitle,
    VuePhoneNumberInput,
  },
  props: { 
    setupData: { 
      type: Object, 
      default: () => {},
    },
    phoneError:{
      type: Boolean, 
      default: () => false,
    },
  },
  data() {
    return {
      rules,
      emailError:null,
      phoneNumberError:false,
      phoneNumber: null,
      phoneCode: null,
      error:null,
    };
  },
  watch:{
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler(val) {
        this.phoneNumber = val.phoneNumber 
        this.phoneCode = countryCode[val.phoneCode] || 'US'
      },
    },
    phoneError: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler(val) {
        this.phoneNumberError = val
        this.error = this.phoneNumber == null ?  i18n.t('field_err_txt') : i18n.t('phone_txt')
      },
    },
  },
  methods: { 
    /**
     * Check Email Availability
     */
    async checkAvailability(email) {
      const { data: { isUserRegistered } } = await this.$apollo.query({
        query: AVAILABLE,
        variables: {
          emailInput: {
            email: email,
          },
        },
      });
      if (isUserRegistered.present) {
        this.emailError = i18n.t('email_exists_txt')
        this.$emit('update:emailError', true);
      } else {
        this.emailError = null
        this.$emit('update:emailError', null);
      }
    },

    /**
     * Validate Phone Number
     */
    onUpdate(payload) {
      this.phoneNumberError = false
      this.error = null
      if (payload.isValid) {
        this.phoneNumberError = false
        this.$emit('update:phoneNumberError', false);
        this.$emit('update:phoneNumber', payload.nationalNumber);
        this.$emit('update:countryCode', payload.countryCallingCode);
      } else {
        this.phoneNumberError = true
        this.error = i18n.t('phone_txt')
        this.$emit('update:phoneNumberError', true);
      }
    },
  },
};
</script>
<style scoped>
.phonenum >>> .country-selector__list{
  top: 150% !important
}
</style>

