<template>
  <v-card flat color="light_grey">
    <v-card-text class="py-md-10">
      <router-link to="/" class="black--text text-decoration-none">
        <img
          src="@/assets/images/logo/with-black.png"
          class="ml-5"
          :width="isMdAndAbove ? '10%' : '30%'"
        />
      </router-link>
    </v-card-text>

    <v-container class="pt-5 mt-10 main-container ">
      <v-row no-gutters>
        <v-flex xs12 md1 />
        <v-flex xs12 md10>
          <v-progress-linear
            :value="tab * 6.25"
            color="primary"
            background-color="#C1CEE0"
            height="10"
            rounded
            class="mb-md-14 mb-4"
          />
          <template v-if="tab === 3 || tab === 4 || tab === 5">
            <PageTitle :heading="$t('welcome')" :subheading="$t('let_start')" />
          </template>
        </v-flex>
        <v-flex xs12 md1>
          <!-- <v-card
						flat
						class="mt-5"
						color="light_grey"
					/> -->
        </v-flex>
      </v-row>

      <v-row no-gutters class="ml-1">
        <v-flex xs12 md1>
          <!-- <v-card
						flat
						class="mt-5"
						color="light_grey"
					/> -->
        </v-flex>
        <v-flex xs12 md10>
          <v-row>
            <AlertBox :message.sync="error" class="ma-1 mb-2" />
            <!--START:: User Account Type Selection-->
            <v-card v-if="tab === 1" flat width="100%" class="ml-1">
              <PageTitle :heading="$t('wlcm_to_with')" heading-class="pb-2" />
              <v-form :ref="`step${tab}Form`">
                <Registration
                  :setup-data="userTypeSelectionStep"
                  :selected-user-type.sync="userTypeSelectionStep"
                />
              </v-form>
            </v-card>
            <!--END:: User Account Type Selection-->

            <v-card v-if="tab === 2" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('early_access')"
                :subheading="$t('early_access_subtitle')"
              />
              <v-form :ref="`step${tab}Form`">
                <EarlyAccess
                  :setup-data="earlyAccessStep"
                  :access-code.sync="earlyAccessStep.accessCode"
                />
              </v-form>
            </v-card>

            <!--START:: Basic Name Section-->
            <v-card v-if="tab === 3" flat width="100%" class="ml-1">
              <v-form :ref="`step${tab}Form`">
                <BasicsName
                  :setup-data="signupStep"
                  :first-name.sync="signupStep.firstName"
                  :last-name.sync="signupStep.lastName"
                />
              </v-form>
            </v-card>
            <!--END:: Basic Name Section-->

            <!--START:: Basic Email Section-->
            <v-card v-if="tab === 4" flat width="100%" class="ml-1">
              <v-form :ref="`step${tab}Form`">
                <BasicsEmail
                  :phone-error="phoneNumberError"
                  :setup-data="signupStep"
                  :email.sync="signupStep.email"
                  :country-code.sync="signupStep.phoneCode"
                  :phone-number.sync="signupStep.phoneNumber"
                  :email-error.sync="emailError"
                  :phone-number-error.sync="phoneNumberError"
                />
              </v-form>
            </v-card>
            <!--END:: Basic Email Section-->

            <!--START:: Basic Password Section-->
            <v-card v-if="tab === 5" flat width="100%" class="ml-1">
              <v-form :ref="`step${tab}Form`">
                <BasicsPassword :password.sync="signupStep.password" />
              </v-form>
            </v-card>
            <!--END:: Basic Password Section-->

            <!--START:: Verification Section-->
            <!-- <v-card
              v-if="tab === 6"
              flat
              width="100%"
              class="ml-1"
              color="light_grey"
            >
              <PageTitle :heading="$t('perfect')" heading-class="pb-3" />
              <v-form :ref="`step${tab}Form`">
                <Verification />
              </v-form>
            </v-card> -->
            <!--END:: Verification Section-->

            <!--START:: Setup Stripe Account Section-->
            <v-card
              v-if="tab === 6"
              flat
              width="100%"
              color="light_grey"
              class="ml-1"
            >
              <PageTitle
                :heading="$t('create_stripe_account')"
                :subheading="$t('stripe_sub_heading')"
              />
              <!-- <v-btn
                href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://connect.stripe.com/connect/default/oauth/test&client_id=ca_FkyHCg7X8mlvCUdMDao4mMxagUfhIwXb&state=1234"
              >
                {{ $t('pick_avail_time_session') }}
              </v-btn> -->
              <v-form :ref="`step${tab}Form`">
                <StripeAccount
                  :callback="saveToStorage"
                  :setup-data="stripeAccountStep"
                  :email="signupStep.email"
                  :phone-number="signupStep.phoneNumber"
                  :stripe-email.sync="stripeAccountStep.email"
                  :stripe-name.sync="stripeAccountStep.name"
                />
              </v-form>
            </v-card>
            <!--END:: Setup Stripe Account Section-->

            <!--START:: Profile Planning Section-->
            <v-card v-if="tab === 7" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('you_are_in')"
                :subheading="$t('set_up_profile')"
              />
              <v-form :ref="`step${tab}Form`">
                <ProfilePlanning
                  :setup-data="planningStep"
                  :selected-categories.sync="planningStep.selectedCategories"
                />
              </v-form>
            </v-card>
            <!--END:: Profile Planning Section-->

            <!--START:: Talent Tags Selection Section-->
            <v-card v-if="tab === 8" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('perfect')"
                :subheading="
                  `${$t('user_tag_title1')} <br> ${$t('user_tag_title2')}`
                "
                heading-class="pb-5"
              />
              <v-form :ref="`step${tab}Form`">
                <Tags
                  :parent-tag-name="planningStep.selectedCategories"
                  :setup-data="talentAreaStep"
                  :selected-tags.sync="talentAreaStep.talentarea"
                />
              </v-form>
            </v-card>
            <!--END:: Talent Tags Selection Section-->

            <!--START:: Welcome Message Section-->
            <v-card v-if="tab === 9" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('welcome_short_description')"
                :subheading="
                  `${$t('make_it_count')} <br> ${$t('change_later')}`
                "
                heading-class="pb-5"
              />
              <v-form :ref="`step${tab}Form`">
                <Welcome
                  :setup-data="introStep"
                  :description.sync="introStep.shortIntro"
                  :welcome-message.sync="introStep.longIntro"
                />
              </v-form>
            </v-card>
            <!--END:: Welcome Message Section-->

            <!--START:: Profile Photo Section-->
            <v-card v-if="tab === 10" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('looking_good')"
                :subheading="$t('add_pic')"
              />
              <v-form :ref="`step${tab}Form`">
                <ProfilePhoto
                  :setup-data="profileImgStep"
                  :image-upload-type.sync="profileImgStep.uploadType"
                  :image-file.sync="profileImgStep.imageFile"
                  :image-file-url.sync="profileImgStep.imageFileUrl"
                  :image-base.sync="profileImgStep.imageBase"
                />
              </v-form>
            </v-card>
            <!--END:: Profile Photo Section-->

            <!--START:: Attach Extra Photos Section-->
            <v-card v-if="tab === 11" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('add_more_pics')"
                :subheading="$t('now_or_later')"
              />
              <v-form :ref="`step${tab}Form`">
                <AttachPhotos
                  :setup-data="profileGalleryStep"
                  :image-file-url.sync="profileGalleryStep.imageFileUrl"
                  :image-gallery.sync="profileGalleryStep.imageFile"
                  :image-uploaded-images.sync="
                    profileGalleryStep.uploadedImages
                  "
                />
              </v-form>
            </v-card>
            <!--END:: Attach Extra Photos Section-->

            <!--START:: Creat First Session Type Section-->
            <v-card v-if="tab === 12" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('session_type_heading')"
                :subheading="$t('session_type_sub_heading')"
                heading-class="pb-0 mb-0"
              />
              <v-form :ref="`step${tab}Form`">
                <SessionType
                  :title.sync="sessionTypeStep.title"
                  :length.sync="sessionTypeStep.length"
                  :charge.sync="sessionTypeStep.charge"
                />
              </v-form>
            </v-card>
            <!--END:: Creat First Session Type Section-->

            <!-- additional sessions? section -->
            <v-card
              v-if="tab === 13"
              flat
              width="100%"
              class="ml-1"
              color="light_grey"
            >
              <PageTitle
                :heading="$t('prices_length')"
                :subheading="$t('like_to_add_session')"
                heading-class="pb-0 mb-0"
              />
              <v-card-text class="pa-0">
                <v-card-actions class="mt-5">
                  <v-btn
                    color="white"
                    class="black--text"
                    tile
                    @click="dialog = !dialog"
                  >
                    {{ $t('yes') }}
                  </v-btn>

                  <v-btn
                    color="white"
                    class="black--text ml-12"
                    tile
                    @click="next()"
                  >
                    {{ $t('maybe_later') }}
                  </v-btn>
                </v-card-actions>
                <v-dialog
                  v-model="dialog"
                  max-width="412"
                  class="ml-16 mt-n16"
                  width="auto"
                >
                  <v-card>
                    <v-form :ref="`step${tab}Form`" lazy-validation>
                      <br />
                      <v-btn
                        icon
                        light
                        absolute
                        right
                        class="mr-n4 mt-n5"
                        color="black"
                        @click="dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <p class="text-lg-h3 text-sm-h3 ml-10 mt-4">
                        {{ $t('create_session') }}
                      </p>
                      <AlertBox :message="error" class="ma-2" />
                      <v-col cols="12">
                        <p class="subtitle-2 subtitle-2">
                          {{ $t('name_session') }}
                        </p>
                        <v-text-field
                          v-model="sessionTypeStep.title"
                          label="e.g., Sing you a song"
                          filled
                          maxlength="50"
                          counter
                          :rules="rules.session_name"
                        />
                      </v-col>

                      <v-col cols="12">
                        <p class="subtitle-2 subtitle-2">
                          {{ $t('how_long') }}
                        </p>
                        <v-select
                          v-model="sessionTypeStep.length"
                          :items="sessionDurations"
                          item-text="text"
                          item-value="value"
                          filled
                          label="Selected Time"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="sessionTypeStep.charge"
                          data-cy="Charges"
                          mask="#########"
                          label="What will you charge for a session?"
                          :rules="rules.session_charge"
                          prefix="$"
                        />
                      </v-col>
                      <v-col md="12" class="text-center">
                        <v-btn
                          width="204"
                          min-width="200"
                          min-height="20"
                          height="48"
                          color="secondary"
                          dark
                          class="white--text"
                          :loading="sessionBtnLoader"
                          @click="createSession()"
                        >
                          {{ $t('create_session') }}
                        </v-btn>
                      </v-col>
                      <br />
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>

            <!--START:: Add Welcome Video Section-->
            <v-card v-if="tab === 14" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('record_video_heading')"
                :subheading="
                  `${$t('record_video_sub_heading1')} <br> ${$t(
                    'record_video_sub_heading2'
                  )}`
                "
              />
              <v-form :ref="`step${tab}Form`">
                <WelcomeVideo
                  :setup-data="profileVideoStep"
                  :video-upload-type.sync="profileVideoStep.uploadType"
                  :video-file.sync="profileVideoStep.videoFile"
                  :video-file-url.sync="profileVideoStep.videoFileUrl"
                  :video-base.sync="profileVideoStep.videoBase"
                />
              </v-form>
            </v-card>
            <!--END:: Add Welcome Video Section-->

            <!--START:: Account Terms Section-->
            <v-card v-if="tab === 15" flat width="100%" class="ml-1">
              <PageTitle
                :heading="$t('terms_heading')"
                :subheading="$t('terms_sub_heading')"
              />
              <v-form :ref="`step${tab}Form`">
                <AccountTerms
                  :setup-data="accountTermStep"
                  :read_accept.sync="accountTermStep.readTerm"
                  :agree_sms.sync="accountTermStep.smsTerm"
                />
              </v-form>
            </v-card>
            <!--END:: Account Terms Section-->

            <!--START:: Account Complete Section-->
            <v-card
              v-if="tab === 16"
              flat
              width="100%"
              class="ml-1"
              color="light_grey"
            >
              <v-form :ref="`step${tab}Form`">
                <AccountComplete />
              </v-form>
            </v-card>
            <!--END:: Account Complete Section-->
          </v-row>

          <!--START:: SUBMIT/BACK/NEXT Buttons Section-->
          <v-row v-if="tab != 16" justify="end" class="mt-10 mb-5">
            <v-btn v-if="isShowBackBtn" color="white" depressed @click="back()">
              {{ $t('back') }}
            </v-btn>
            <v-btn
              v-if="tab <= 15"
              :disabled="
                (tab === 15 &&
                  (!accountTermStep.readTerm || !accountTermStep.smsTerm)) ||
                  tab === 6
              "
              class="ml-12  mr-md-0"
              color="primary"
              :loading="loading"
              @click="handleNextClicked()"
            >
              {{ $t('continue') }}
            </v-btn>
            <v-btn
              v-if="tab == 14"
              color="white"
              class="black--text ml-12"
              tile
              @click="next()"
            >
              {{ $t('maybe_later') }}
            </v-btn>
          </v-row>
        </v-flex>
        <v-flex xs12 md1 />
      </v-row>
    </v-container>
    <v-dialog
      v-model="showCreatingDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="tertiary" class="title">
          <v-spacer />
          {{ creatingMessage }}
          <v-spacer />
        </v-toolbar>
        <v-progress-linear :indeterminate="loading" />
        <v-card-actions>
          <v-layout justify-center row wrap>
            <v-flex xs3>
              <v-btn
                :disabled="loading"
                color="primary"
                @click="showCreatingDialog = false"
              >
                {{ $t('dismiss') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

// graphql
import {
  AVAILABLE,
  CREATE_TALENT,
  REGISTER,
  UPDATE_TALENT_INTRO,
  SET_TALENT_AREA,
  UPDATE_PROFILE_IMG,
  UPDATE_PROFILE_VIDEO,
  UPDATE_TALENT_GALLERY,
  CREATE_STRIPE_ACCOUNT,
  UPLOAD_BASE64_FILE,
  UPDATE_USER_FINISH,
  AUTHORIZE_STRIPE_ACCOUNT,
} from '@/graphql/account'

import { CHECK_EARLY_ACCESS } from '@/graphql/authentication'

import { CREATE_SESSION_TYPE } from '@/graphql/sessions'

// utils
import { initialState } from '../../components/auth/signup.initialState'
import i18n from '@/utils/i18n'

// modules
import PageTitle from '@/components/talent-setup/PageTitle.vue'
import Registration from '@/components/talent-setup/Registration.vue'
import EarlyAccess from '@/components/talent-setup/EarlyAccess.vue'
import BasicsName from '@/components/talent-setup/BasicsName.vue'
import BasicsEmail from '@/components/talent-setup/BasicsEmail.vue'
import BasicsPassword from '@/components/talent-setup/BasicsPassword.vue'
import ProfilePlanning from '@/components/talent-setup/ProfilePlanning.vue'
import Verification from '@/components/talent-setup/Verification.vue'
import Welcome from '@/components/talent-setup/Welcome.vue'
import Tags from '@/components/talent-setup/Tags.vue'
import ProfilePhoto from '@/components/talent-setup/ProfilePhoto.vue'
import AttachPhotos from '@/components/talent-setup/AttachPhotos.vue'
import StripeAccount from '@/components/talent-setup/StripeAccount.vue'
import SessionType from '@/components/talent-setup/SessionType.vue'
import WelcomeVideo from '@/components/talent-setup/WelcomeVideo.vue'
import AccountTerms from '@/components/talent-setup/AccountTerms.vue'
import AccountComplete from '@/components/talent-setup/AccountComplete.vue'

export default {
  name: 'TalentAccountSetup',
  components: {
    PageTitle,
    Registration,
    EarlyAccess,
    BasicsName,
    BasicsEmail,
    BasicsPassword,
    ProfilePlanning,
    Verification,
    Welcome,
    Tags,
    ProfilePhoto,
    AttachPhotos,
    StripeAccount,
    SessionType,
    WelcomeVideo,
    AccountTerms,
    AccountComplete,
  },
  data() {
    return initialState
  },
  computed: {
    ...mapGetters(['user', 'jwt']),
    isMdAndAbove() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    isShowBackBtn() {
      // TODO might need to be 16
      return this.tab > 1 && this.tab != 15 && this.tab != 7
    },
  },
  updated() {
    this.authData = {
      email: this.user.email,
      accesstoken: this.jwt,
    }
  },
  async mounted() {
    this.tryAuthorizeStripeAccount()
    // if (this.$route.params.tabs) {
    //   this.tab = this.$route.params.tabs
    // } else if (this.$route.query.tabs) {
    //   this.tab = parseInt(this.$route.query.tabs)
    //   this.value = 34.7
    // } else if (this.user.email) {
    //   this.$router.push({ name: 'Home' })
    // }
    // const cache = this.loadFromStorage()
    // if (cache.signup && cache.step) {
    //   if (tab > 4 && signup) {
    //     this.signupStep = signup
    //     this.tab = tab
    //   }
    // }
  },
  methods: {
    async tryAuthorizeStripeAccount() {
      const code = this.$route.query.code
      const cache = this.loadFromStorage()
      try {
        if (code && cache.jwt && cache.signup?.email) {
          this.tab = 7
          this.jwt = cache.jwt
          this.signupStep = cache.signup
          await this.$store.commit('setJwt', cache.jwt)
          await this.$store.commit('setUser', cache.user)
          const {
            data: { authorizeStripeAccount },
          } = await this.$apollo.mutate({
            mutation: AUTHORIZE_STRIPE_ACCOUNT,
            variables: {
              stripeData: {
                authorizationCode: code,
                accesstoken: cache.jwt,
                email: cache.signup.email,
              },
            },
          })
        }
      } catch {
        this.tab = 1
        this.jwt = null
        this.signupStep = null
        await this.$store.commit('setJwt', null)
      }
    },
    saveToStorage() {
      const cachedData = JSON.stringify({
        signup: this.signupStep,
        jwt: this.jwt,
        user: this.user,
      })
      console.log('saveToStorage', cachedData)
      localStorage.setItem('signUpData', cachedData)
    },

    loadFromStorage() {
      try {
        return JSON.parse(localStorage.getItem('signUpData'))
      } catch {
        return {}
      }
    },

    /**
     * Handle Next Clicked
     */
    handleNextClicked() {
      switch (`step${this.tab}Form`) {
        case 'step2Form':
          this.checkAccessCode()
          return
        case 'step5Form':
          this.signUp()
          return

        case 'step8Form':
          this.setTalentArea()
          return

        case 'step9Form':
          this.updateIntro()
          return

        case 'step10Form':
          switch (this.profileImgStep.uploadType) {
            case 'object':
              this.updateProfileImage()
              break

            default:
              this.updateProfileImageBase64()
              break
          }
          return

        case 'step11Form':
          this.updateProfileGallery()
          return
        // case 'step11Form':
        //   this.createStripeAccount()
        //   return

        case 'step12Form':
          this.createSession()
          return

        case 'step13Form':
          switch (this.profileVideoStep.uploadType) {
            case 'object':
              this.updateProfileVideo()
              break

            default:
              this.updateProfileVideoBase64()
              break
          }
          return
        case 'step15Form':
          this.updateUserFinish()
          return
        default:
          this.validateStep()
          return
      }
    },
    /**
     * handle signup validation
     * to check if password is null
     * (when user doesnt input password and click on next)
     */

    validateCreateUser() {
      if (!this.signupStep.password) {
        this.error = i18n.t('pwd_txt')
        return false
      }
      return true
    },

    /**
     * Validate The Step Form
     */
    validateStep() {
      if (this.signupStep.phoneNumber == null && this.tab == 4) {
        this.phoneNumberError = true
      }

      if (
        this.$refs[`step${this.tab}Form`].validate() &&
        this.tab !== 5 &&
        this.emailError === null &&
        !this.phoneNumberError
      ) {
        this.next()
      }
    },

    /**
     * Handle Next Click
     */
    next() {
      if (!this.userTypeSelectionStep.isTalent && this.tab === 1) {
        this.value += 6.69 * 2
        this.tab += 2
      } else {
        this.value = this.value + 6.69
        this.tab++
      }
    },

    /**
     * Handle Back Click
     */
    back() {
      if (this.tab === 3) {
        this.value -= 6.69 * 2
        this.tab -= 2
      } else {
        this.value = this.value - 6.69
        this.tab >= 1 ? this.tab-- : 0
      }
    },

    async checkAccessCode() {
      this.error = null
      if (this.$refs[`step${this.tab}Form`].validate()) {
        this.loading = true
        try {
          const {
            data: { checkEarlyAccess },
          } = await this.$apollo.mutate({
            mutation: CHECK_EARLY_ACCESS,
            variables: {
              registrationCode: this.earlyAccessStep?.accessCode,
            },
          })

          if (checkEarlyAccess.success === true) {
            this.next()
          } else {
            this.error = this.$t(checkEarlyAccess.error)
          }
          this.loading = false
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },

    /**
     * Create User By Selected Type
     */
    async signUp() {
      this.error = null
      const isvalid = this.validateCreateUser()
      if (isvalid && this.$refs[`step${this.tab}Form`].validate()) {
        if (this.userTypeSelectionStep.isTalent) {
          this.loading = true
          try {
            const registerData = {
              ...this.signupStep,
              registrationCode: this.earlyAccessStep.accessCode,
            }
            const {
              data: { registerTalent },
            } = await this.$apollo.mutate({
              mutation: CREATE_TALENT,
              variables: {
                talentData: registerData,
              },
            })

            // Set User in vuex
            if (registerTalent.success) {
              registerTalent.talent.user.istalent = true
              await this.$store.commit('setUser', registerTalent.talent.user)
              await this.$store.commit('setJwt', registerTalent.accesstoken)
              this.next()
              this.loading = false
            } else {
              this.error = this.$t(registerTalent.error)
              this.loading = false
            }
          } catch (e) {
            this.error =
              'Oops! There was an error in your registration. Please review your data and try again.'
            console.error(e) // eslint-disable-line
            this.loading = false
            throw e
          }
        } else {
          this.createUser()
        }
      }
    },

    /**
     * Create Normal User
     */
    async createUser() {
      this.loading = true
      try {
        const {
          data: { registerUser },
        } = await this.$apollo.mutate({
          mutation: REGISTER,
          variables: {
            registerData: { ...this.signupStep },
          },
        })

        // Set User Info
        if (registerUser.success) {
          registerUser.user.istalent = false
          await this.$store.commit('setUser', registerUser.user)
          await this.$store.commit('setJwt', registerUser.accesstoken)

          this.$router.push('/account/settings')
        } else {
          this.error = this.$t(registerUser.error)
        }
      } catch (e) {
        this.loading = false
        this.error =
          'Oops! There was an error in your registration. Please review your data and try again.'
        console.error(e) // eslint-disable-line
        throw e
      }
    },

    /**
     * Check Email Availability
     */
    async checkAvailability() {
      const {
        data: { isUserRegistered },
      } = await this.$apollo.query({
        query: AVAILABLE,
        variables: {
          emailInput: {
            email: this.signupStep.email,
          },
        },
      })
      this.usernameError = isUserRegistered.present
        ? 'Email is already taken.'
        : null
    },

    /**
     * Update Talent Intro
     */
    async updateIntro() {
      this.error = null
      if (this.$refs[`step${this.tab}Form`].validate()) {
        this.loading = true
        try {
          const {
            data: { updateTalent },
          } = await this.$apollo.mutate({
            mutation: UPDATE_TALENT_INTRO,
            variables: {
              talentData: {
                ...this.authData,
                ...this.introStep,
              },
            },
          })

          // Set User Intro in vuex
          if (updateTalent.success) {
            this.user.shortIntro = this.description
            this.user.longIntro = this.welcomeMessage
            await this.$store.commit('setUser', this.user)
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(updateTalent.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },

    /**
     * Set Talent Area
     */
    async setTalentArea() {
      this.error = null
      if (this.$refs[`step${this.tab}Form`].validate()) {
        this.loading = true
        const talentData = [
          ...this.planningStep.selectedCategories,
          ...this.talentAreaStep.talentarea,
        ]
        console.log(talentData)
        try {
          const {
            data: { setTalentArea },
          } = await this.$apollo.mutate({
            mutation: SET_TALENT_AREA,
            variables: {
              talentareaData: {
                ...this.authData,
                talentarea: talentData,
              },
            },
          })

          // Set User Intro in vuex
          if (setTalentArea.success) {
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(setTalentArea.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },

    /**
     * Update Profile Image
     */
    async updateProfileImage() {
      this.error = null
      if (this.profileImgStep.imageFile) {
        this.loading = true
        try {
          const {
            data: { updateProfileImage },
          } = await this.$apollo.mutate({
            mutation: UPDATE_PROFILE_IMG,
            variables: {
              imageFile: this.profileImgStep.imageFile,
              tokenData: { ...this.authData },
            },
          })

          // Set User ProfileImage in vuex
          if (updateProfileImage.success) {
            this.profileImgStep.imageFileUrl = updateProfileImage.url
            await this.$store.commit('setprofileImage', updateProfileImage.url)
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(updateProfileImage.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.error = i18n.t('profile_pic_msg')
      }
    },
    /**
     * Update Profile Image base64
     */
    async updateProfileImageBase64() {
      this.error = null
      console.log(this.profileImgStep)
      if (this.profileImgStep.imageFileUrl) {
        this.loading = true
        try {
          const {
            data: { uploadBase64File },
          } = await this.$apollo.mutate({
            mutation: UPLOAD_BASE64_FILE,
            variables: {
              baseData: {
                base64: this.profileImgStep.imageBase,
                email: this.authData.email,
                accesstoken: this.authData.accesstoken,
                contentType: 'image/png',
                updateOption: 'profile',
              },
            },
          })

          // Set User ProfileImage in vuex
          if (uploadBase64File.success) {
            this.profileImgStep.imageFileUrl = uploadBase64File.url
            await this.$store.commit('setprofileImage', uploadBase64File.url)
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(updateProfileImage.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.error = i18n.t('profile_pic_msg')
      }
    },

    /**
     * Update Profile Gallery
     */
    async updateProfileGallery() {
      this.error = null
      if (this.profileGalleryStep.imageFile.length) {
        this.loading = true
        try {
          const {
            data: { uploadTalentGallery },
          } = await this.$apollo.mutate({
            mutation: UPDATE_TALENT_GALLERY,
            variables: {
              galleryInput: {
                imageFile: [...this.profileGalleryStep.imageFile],
                ...this.authData,
              },
            },
          })

          if (uploadTalentGallery.success) {
            this.next()
            this.loading = false
            this.profileGalleryStep.uploadedImages = uploadTalentGallery.gallery
            this.profileGalleryStep.imageFile = []
          } else {
            this.error = this.$t(uploadTalentGallery.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.next()
        // this.error = i18n.t('profile_gallery_msg');
      }
    },

    async createStripeAccount() {
      this.error = null
      if (this.$refs[`step${this.tab}Form`].validate()) {
        this.loading = true
        try {
          const {
            data: { createStripeAccount },
          } = await this.$apollo.mutate({
            mutation: CREATE_STRIPE_ACCOUNT,
            variables: {
              stripeData: {
                accesstoken: this.authData.accesstoken,
                email: this.stripeAccountStep.email,
                name: this.stripeAccountStep.name,
              },
            },
          })

          // Set User Info
          if (createStripeAccount.success) {
            this.user.stripeId = createStripeAccount.stripeId
            await this.$store.commit('setUser', this.user)
            this.next()
            this.loading = false
          } else {
            this.loading = false
            this.error = this.$t(createStripeAccount.error)
          }
        } catch (e) {
          this.loading = false
          this.error = e
          console.error(e) // eslint-disable-line
        }
      }
    },

    /**
     * Create New Session Type
     */
    async createSession() {
      this.error = null
      if (this.$refs[`step${this.tab}Form`].validate()) {
        this.loading = true
        this.sessionBtnLoader = true
        try {
          this.sessionTypeStep.charge = this.sessionTypeStep.charge
          const {
            data: { createSessionType },
          } = await this.$apollo.mutate({
            mutation: CREATE_SESSION_TYPE,
            variables: {
              sessionData: {
                ...this.authData,
                ...this.sessionTypeStep,
              },
            },
          })
          if (createSessionType.success) {
            this.$refs[`step${this.tab}Form`].reset()
            this.dialog = false
            this.sessionBtnLoader = false
            this.loading = false
            this.sessionTypeStep = {
              title: null,
              length: null,
              charge: null,
            }
            if (this.tab === 12) {
              this.next()
            }
          } else {
            this.loading = false
            this.sessionBtnLoader = false
            this.error = this.$t(createSessionType.error)
          }
        } catch (e) {
          this.loading = false
          this.sessionBtnLoader = false
          this.error = e
          console.error(e) // eslint-disable-line
        }
      }
    },

    /**
     * Update Profile Video
     */
    async updateProfileVideo() {
      this.error = null
      if (this.profileVideoStep.videoFile) {
        this.loading = true
        try {
          const {
            data: { updateProfileVideo },
          } = await this.$apollo.mutate({
            mutation: UPDATE_PROFILE_VIDEO,
            variables: {
              videoFile: this.profileVideoStep.videoFile,
              tokenData: { ...this.authData },
            },
          })

          // Set User ProfileImage in vuex
          if (updateProfileVideo.success) {
            this.profileVideoStep.videoFileUrl = updateProfileVideo.url
            this.user.profileVideo = updateProfileVideo.url
            await this.$store.commit('setUser', this.user)
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(updateProfileVideo.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.next()
        // this.error = i18n.t('profile_video_msg');
      }
    },

    /**
     * Update Profile Video base64
     */
    async updateProfileVideoBase64() {
      this.error = null
      if (this.profileVideoStep.videoBase) {
        this.loading = true
        try {
          const {
            data: { uploadBase64File },
          } = await this.$apollo.mutate({
            mutation: UPLOAD_BASE64_FILE,
            variables: {
              baseData: {
                base64: this.profileVideoStep.videoBase,
                email: this.authData.email,
                accesstoken: this.authData.accesstoken,
                contentType: 'video/mp4',
                updateOption: 'portfolio',
              },
            },
          })

          // Set User ProfileImage in vuex
          if (uploadBase64File.success) {
            this.profileVideoStep.videoFileUrl = uploadBase64File.url
            this.user.profileVideo = uploadBase64File.url
            await this.$store.commit('setUser', this.user)
            this.next()
            this.loading = false
          } else {
            this.error = this.$t(uploadBase64File.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.next()
        // this.error = i18n.t('profile_video_msg');
      }
    },

    /**
     * Update didFinishRegistration as True
     */
    async updateUserFinish() {
      this.error = null
      this.loading = true
      try {
        const {
          data: { updateUserFinish },
        } = await this.$apollo.mutate({
          mutation: UPDATE_USER_FINISH,
          variables: {
            userData: {
              email: this.user.email,
              accesstoken: this.jwt,
              didFinishRegistration: true,
            },
          },
        })

        // Set User Finish Flag in vuex
        if (updateUserFinish.success) {
          await this.$store.commit('setUserFinish', true)
          await this.$store.dispatch('getUser')
          await this.$store.commit('setIsTalent', updateUserFinish.istalent)
          this.next()
          this.loading = false
        } else {
          this.error = this.$t(updateUserFinish.error)
          this.loading = false
        }
      } catch (e) {
        this.error = e
        this.loading = false
        console.error(e) // eslint-disable-line
      }
    },

    /**
     * Handle Submit Click
     */
    handleSubmitClick() {
      const redirectTo = '/talent/sessions'
      this.$router.push(redirectTo)
    },
  },
}
</script>

<style lang="scss">
.social.v-btn-toggle--group > .v-btn.v-btn.v-btn--active {
  background-color: $primary !important;
  color: $white !important;
}
.v-btn {
  font-weight: 700;
}
.doted-border {
  border-style: dashed !important;
  border-width: 2px !important;
  border-color: $light-grey !important;
}
.v-tab {
  text-transform: none !important;
  color: $black !important;
}
.theme--light.v-tabs-items {
  background-color: $light-grey !important;
}
.v-label {
  font-size: 14px !important;
}
.v-slide-group__content {
  align-items: flex-start;
}
.video-border-radius {
  border-radius: 8px;
}
.white-label label {
  color: $white !important;
}
.rounded-4 {
  border-radius: 4px;
}
.v-main__wrap {
  background: $light_grey !important;
}
.main-container {
  padding: 28px;
  width: 80%;
}
@media only screen and (max-width: 600px) {
  .main-container {
    padding: 28px;
    width: 100%;
  }
}
</style>
