import { rules } from '@/utils/validation'
import { sessionDurations } from '@/utils/static'

export const initialState = {
  showCreatingDialog: false,
  showConfirmDialog: false,
  formMode: null,
  referral: null,
  creatingMessage: 'Now Creating Your Profile...',
  value: 6.6,
  loading: false,
  success: false,
  errors: false,
  socialMediaError: false,
  rules,
  emailError: null,
  phoneNumberError: false,
  loadingAvatar: false,
  checkingUsername: false,
  filteredTalent: [],
  tab: 1,
  dialog: false,
  languageCodes: {
    en: 1,
  },
  userTypeSelectionStep: {
    name: null,
    isTalent: null,
  },
  earlyAccessStep: {
    accessCode: null,
  },
  signupStep: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    password: null,
    phoneCode: null,
  },
  planningStep: {
    selectedCategories: [],
  },
  introStep: {
    shortIntro: null,
    longIntro: null,
  },
  profileImgStep: {
    imageFile: null,
    imageFileUrl: null,
    uploadType: null,
    imageBase: null,
  },
  profileGalleryStep: {
    imageFile: [],
    imageFileUrl: [],
    uploadedImages: [],
  },
  stripeAccountStep: {
    email: null,
    name: null,
  },
  profileVideoStep: {
    videoFile: null,
    videoFileUrl: null,
    videoBase: null,
    uploadType: 'object',
  },
  talentAreaStep: {
    talentarea: [],
  },
  accountTermStep: {
    readTerm: false,
    smsTerm: false,
  },
  selectedTags: [],
  sessionTypeStep: {
    title: null,
    length: null,
    charge: null,
  },
  sessionDurations,
  sessionBtnLoader: false,
  authData: {
    email: null,
    accesstoken: null,
  },
  error: null,
}
