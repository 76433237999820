<template>
  <v-card-text class="pa-0">
    <v-card color="white" height="100%" flat class="pa-8">
      <PageTitle :heading="$t('signup_as')" :type="'style1'" />
      <v-row no-gutters>
        <v-radio-group
          v-model="selectedUserType"
          class="my-0"
          row
          hide-details
          :rules="selectedUserType === null ? rules.required : []"
        >
          <v-radio
            v-for="(category, index) in signupCategories"
            :key="category.name"
            :label="category.name"
            :value="category.isTalent"
            class="pa-1 rounded-4 pr-4 mt-sm-3"
            :class="{ 'ml-md-3  ': index != 0 }"
            on-icon="mdi-checkbox-marked-circle-outline"
            :color="selectedUserType != null ? 'white' : 'primary'"
            active-class="primary white-label"
            @click="$emit('update:selected-user-type', category)"
          />
        </v-radio-group>
      </v-row>
    </v-card>
  </v-card-text>
</template>

<script>
import PageTitle from '@/components/talent-setup/PageTitle.vue'

// utils
import { rules } from '@/utils/validation.js'

export default {
  components: {
    PageTitle,
  },
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules,
      selectedUserType: null,
      signupCategories: [
        {
          name: 'Member account',
          isTalent: false,
        },
        {
          name: 'Host account',
          isTalent: true,
        },
      ],
    }
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.selectedUserType = val.isTalent
      },
    },
  },
}
</script>
<style>
.v-input--selection-controls__ripple {
  height: 26px !important;
  width: 26px !important;
  left: -8px !important;
  top: calc(50% - 20px) !important;
}
</style>
