<template>
	<v-card-text class="light_grey">
		<v-card
			flat
			height="100%"
			color="light_grey"
		>
			<v-row
				no-gutters
				align="center"
				justify="center"
			>
				<v-flex
					xs12
					sm12
					md5
					xl4
				>
					<v-card
						flat
						rounded="lg"
						height="100%"
						width="100%"
						class="pa-5"
					>
						<v-card-text class="text--primary text-center">
							<v-row no-gutters>
								<label class="font-weight-bold">{{ $t('session_type_message') }}</label>
								<v-flex
									xs12
									my-4
								>
									<v-text-field
										placeholder="Meet-and-Greet"
										outlined
										:rules="rules.session_name"
										hide-details="auto"
										@input="$emit('update:title', $event)"
									/>
								</v-flex>
								<label class="font-weight-bold">{{ $t('how_long') }} </label>
								<v-flex
									xs12
									my-4
								>
									<v-select
										:items="sessionDurations"
										item-text="text"
										item-value="value"
										placeholder="Select"
										outlined
										:rules="rules.required"
										hide-details="auto"
										@change="$emit('update:length', $event)"
									/>
								</v-flex>
								<label class="font-weight-bold">{{ $t('charge') }}</label>
								<v-flex
									xs12
									my-4
								>
									<v-text-field
										outlined
										mask="#########"
										placeholder="Charge"
										prefix="$"
										:rules="[
											v => !!v || $t('session_charge'),
											v => /^[0-9.]*$/.test(v) || $t('session_charge_num'),
											v => v >= this.length*unitSessionPrice || $t('session_charge_min')
										]"
										hide-details="auto"
										@input="$emit('update:charge', $event)"
									/>
								</v-flex>
							</v-row>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-row>
		</v-card>
	</v-card-text>
</template>
<script>

// Utils
import { rules } from "@/utils/validation";
import { sessionDurations } from "@/utils/static";
import { GET_UNIT_SESSION_PRICE } from "@/graphql/sessions";

export default {
	props: { 
		length: { 
		type: Number, 
		default: () => null,
		},
	},
	data() {
		return {
			sessionDurations,
			rules,
			unitSessionPrice: null,
		}
	},
  mounted() {
    this.getUnitSessionPrice()
    console.log('here', this.$store.state.jwt)
  },
	methods:{
		async getUnitSessionPrice(){
			this.loading = true
			const { data: { fetchUnitSessionPrice } } = await this.$apollo.query({
				query: GET_UNIT_SESSION_PRICE,
			});
			if (fetchUnitSessionPrice.success) {
				this.unitSessionPrice = fetchUnitSessionPrice.unitSessionPrice
			} 
		},
	}
};
</script>
