<template>
  <div class="pb-5 column">
    <div class="row">
      <v-img
        class="hover stripeImg"
        @click="connectToStripe()"
        :src="require('@/assets/images/stripe.png')"
      />
    </div>
    <v-card-text class="row">
      {{ $t('stripe_detail') }}
    </v-card-text>
  </div>
</template>
<script>
import { rules } from '@/utils/validation.js'
import PageTitle from '@/components/talent-setup/PageTitle.vue'
import { REDIRECT_URL, STRIPE_LIVE_CLIENT_ID } from '../../consts'
export default {
  components: {
    PageTitle,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => {},
    },
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules,
    }
  },
  methods: {
    // To test the onboarding process, you can provide (000) 000-0000 as a phone number. Instead of sending you an SMS message or email, Stripe lets you complete verification with the code 000-000.
    connectToStripe() {
      this.callback()
      const callbackUrl = encodeURIComponent(REDIRECT_URL)
      const fullUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${STRIPE_LIVE_CLIENT_ID}&suggested_capabilities[]=transfers&redirect_uri=${callbackUrl}&stripe_user[business_type]=company&stripe_user[email]=${this.email}&stripe_user[phone_number]=${this.phoneNumber}`
      window.location.replace(fullUrl)
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/style.scss';

.stripeImg {
  margin: 1em 3em 0 1em;
  max-width: 20em;
  width: calc(100% - 4em);
  height: auto;
}

.v-application .error--text {
  color: black !important;
  opacity: 0.5;
  font-weight: 600;
}
</style>
