<template>
  <v-card-text class="pa-0">
    <v-card color="white" class="pa-8" height="100%" flat>
      <!-- <PageTitle
        :type="'style1'"
        :heading="$t('how_do_you_plan')"
      /> -->
      <v-input
        class="px-4"
        hidden
        :rules="[
          () => Boolean(selectedCategories.length) || 'Select at least one.',
        ]"
      />
      <!-- <v-col cols="12" sm="12" xs="12" md="5"> -->
      <!-- <v-radio-group v-model="selectedCategories" column> -->
      <v-row v-for="(category, n) in categories" :key="n">
        <v-checkbox
          v-model="selectedCategories"
          class="border-8 rounded-4 mt-0 pa-1"
          :label="category.name"
          :value="category.slug"
          @click="$emit('update:selectedCategories', selectedCategories)"
        />
      </v-row>
      <!-- </v-col> -->
      <!-- </v-radio-group> -->
    </v-card>
  </v-card-text>
</template>
<script>
import PageTitle from '@/components/talent-setup/PageTitle.vue'
//utils
import i18n from '@/utils/i18n.js'
import { GET_TALENT_AREA } from '@/graphql/master'

export default {
  components: {
    PageTitle,
  },
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedCategories: [],
      categories: [],
    }
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: false,
      handler(val) {
        console.log(val)
        this.selectedCategories = [...val]
      },
    },
  },
  updated() {
    console.log(this)
  },
  async mounted() {
    this.selectedCategories = this.$props.setupData.selectedCategories
    const {
      data: { getTalentArea },
    } = await this.$apollo.query({
      query: GET_TALENT_AREA,
      variables: {
        topLevel: true,
      },
    })
    this.categories = getTalentArea
      .filter(x => x.level === 1)
      .map(x => ({
        ...x,
        slug: x.name,
      }))
  },
  methods: {},
}
</script>
