<template>
  <v-card-text class="pa-0">
    <v-card flat height="100%" class="pa-8">
      <v-row no-gutters align="center">
        <v-flex xs12>
          <PageTitle :subheading="$t('add_six_pics')" :type="'style1'" />
        </v-flex>
        <v-flex xs12>
          <AlertBox :message="picFileTypeError" class="ma-1 mb-2" />
        </v-flex>
        <template v-if="profilePicUrls && profilePicUrls.length">
          <v-card
            v-for="(profilePicUrl, index) in profilePicUrls"
            :key="index"
            flat
            rounded="lg"
            color="dark_grey"
            height="240"
            width="240"
            class="ma-2"
            :img="profilePicUrl.image ? profilePicUrl.image : profilePicUrl"
          >
            <v-flex xs12 sm12 md3 align-self-end class="pa-2 pb-6 text-right">
              <v-btn
                color="white"
                small
                height="30"
                depressed
                @click="removeImage(index, profilePicUrl.id)"
              >
                <v-icon center>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-flex>
          </v-card>
        </template>
        <v-card
          v-else
          flat
          rounded="lg"
          color="dark_grey"
          height="240"
          width="240"
        >
          <input
            ref="file"
            hidden
            type="file"
            accept="image/*"
            @change="handleFileImageUpload()"
          />
          <v-btn
            class="upload-btn"
            color="primary"
            medium
            @click="$refs.file.click()"
          >
            {{ $t('upload_profile') }}
          </v-btn>
        </v-card>
        <v-card
          v-if="
            profilePicUrls && profilePicUrls.length && profilePicUrls.length < 6
          "
          flat
          rounded="lg"
          color="dark_grey"
          height="240"
          width="240"
          :class="{ 'ma-2': profilePicUrls && profilePicUrls.length }"
        >
          <input
            ref="file"
            hidden
            type="file"
            accept="image/*"
            @change="handleFileImageUpload()"
          />
          <v-btn class="upload-btn" color="white" @click="$refs.file.click()">
            <v-icon dark class="black--text">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
import { mapGetters } from 'vuex'

import PageTitle from '@/components/talent-setup/PageTitle.vue'

//utils
import i18n from '@/utils/i18n'
// graphql
import { DELETE_TALENT_GALLERY } from '@/graphql/account'
import { PROFILE } from '@/graphql/talent'

export default {
  components: {
    PageTitle,
  },
  props: {
    setupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profilePicUrls: [],
      profilePics: [],
      uploadedPics: [],
      picFileTypeError: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt']),
  },
  watch: {
    setupData: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.profilePicUrls = [...val.imageFileUrl]
        this.profilePics = [...val.imageFile]
        this.uploadedPics = [...val.uploadedImages]
      },
    },
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    async getProfile() {
      this.loading = true
      const {
        data: { getBookingPageInfo },
      } = await this.$apollo.query({
        query: PROFILE,
        variables: {
          username: this.user.username,
        },
      })
      if (getBookingPageInfo.success) {
        this.loading = false
        for (let images of getBookingPageInfo.gallery) {
          this.profilePicUrls.push(images)
        }
        // this.profileVideoUrl=getBookingPageInfo.profile.introVideo
      }
    },
    handleFileImageUpload() {
      this.picFileTypeError = null
      /*
        Set the local file variable to what the user has selected.
      */
      const profilePic = this.$refs.file.files[0]
      this.$refs.file.value = null
      /*
        Check to see if the file is not empty.
        Ensure the file is an image file.
      */
      if (profilePic && /\.(jpe?g|png)$/i.test(profilePic.name)) {
        if (profilePic.size > 6000000) {
          this.showError('file_size_msg')
          return
        }
        this.profilePics.push(profilePic)

        /*
          Initialize a File Reader object
        */
        const reader = new FileReader()

        /*
          Add an event listener to the reader that when the file
          has been loaded, we flag the show preview as true and set the
          image to be what was read from the reader.
        */
        reader.addEventListener(
          'load',
          () => {
            this.showRemoveAvatarButton = true
            this.profilePicUrls.push(reader.result)
            this.$emit('update:image-file-url', this.profilePicUrls)
          },
          false
        )

        /*
        Fire the readAsDataURL method which will read the file in and
        upon completion fire a 'load' event which we will listen to and
        display the image in the preview.
        */
        reader.readAsDataURL(profilePic)
        this.$emit('update:image-gallery', this.profilePics)
      } else {
        this.showError('invalid_file_type')
      }
    },

    removeImage(imageIndex, id) {
      this.profilePicUrls = this.profilePicUrls.filter(
        (profilePicUrl, index) => index !== imageIndex
      )
      this.profilePics = this.profilePics.filter(
        (profilePicUrl, index) => index !== imageIndex
      )
      if (this.uploadedPics[imageIndex]) {
        this.deleteProfileGallery(this.uploadedPics[imageIndex].id)
        this.uploadedPics = this.uploadedPics.filter(
          (upload, index) => index !== imageIndex
        )
      }
      if (id) {
        this.deleteProfileGallery(id)
        this.uploadedPics = this.uploadedPics.filter(
          (upload, index) => index !== imageIndex
        )
      }

      if (!this.profilePicUrls.length) {
        this.$refs.file.value = ''
        this.profilePicUrls = []
        this.profilePics = []
        this.uploadedPics = []
      }

      // if (!this.profilePics.length) {
      //   this.$refs.file.value = ''
      //   this.profilePicUrls = []
      //   this.profilePics = []
      //   this.uploadedPics = []
      // }
      this.$emit('update:image-gallery', this.profilePics)

      this.$emit('update:image-file-url', this.profilePicUrls)
      this.$emit('update:image-uploaded-images', this.uploadedPics)
    },

    /**
     * delete photo
     */
    async deleteProfileGallery(id) {
      try {
        const {
          data: { deleteTalentGallery },
        } = await this.$apollo.mutate({
          mutation: DELETE_TALENT_GALLERY,
          variables: {
            galleryInput: {
              email: this.user.email,
              fileid: id,
              accesstoken: this.jwt,
            },
          },
        })

        if (deleteTalentGallery.success) {
        } else {
        }
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },

    showError(message) {
      this.picFileTypeError = i18n.t(message)
      this.$refs.file.value = ''
      this.$nextTick(() => {
        this.timeOut = setTimeout(() => {
          this.picFileTypeError = null
        }, 3000)
      })
    },
  },
}
</script>
<style scoped>
.upload-btn {
  position: absolute;
  bottom: 42%;
  right: 18%;
  margin-left: auto;
  margin-right: auto;
}
</style>
