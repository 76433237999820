<template>
  <v-card-text class="pa-8">
    <v-card
      flat
      height="100%"
    >
      <h3 class="mt-3 mb-5 mb-3 ml-1">
        {{ $t('description') }}
      </h3>

      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          sm="12"
        >
          <h5 class="more-light-text medium-text  mb-3 	d-sm-none d-md-block d-md-flex flex-end">
            {{ $t('max_20_char') }}
          </h5>
          <v-text-field
            :value="setupData.shortIntro"
            placeholder="Type a short description. Eg. Wolf, Ex-Villain"
            outlined
            required
            hide-details="auto"
            :rules="rules.description"
            @input="$emit('update:description', $event)"
          />
          <h5 class="d-flex more-light-text medium-text  mt-3 d-md-none flex-end">
            {{ $t('max_20_char') }}
          </h5>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      height="100%"
      class="pt-10"
    >
      <h3 class="mt-3 mb-5 mb-3 ml-1">
        {{ $t('profile_welcome_message') }}
      </h3>

      <v-row no-gutters>
        <v-col
          cols="12"
        >
          <h5 class="more-light-text medium-text  mb-3  d-sm-none d-md-block d-md-flex flex-end">
            {{ $t('max_480_char') }}
          </h5>
          <v-textarea
            :value="setupData.longIntro"
            auto-grow
            outlined
            rows="2"
            row-height="60"
            placeholder="Write your welcome message"
            required
            hide-details="auto"
            :rules="rules.welcomeMessage"
            @input="$emit('update:welcome-message', $event)"
          />
          <h5 class="d-flex more-light-text medium-text  d-md-none  mt-3 flex-end">
            {{ $t('max_480_char') }}
          </h5>
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>
<script>
// Utils
import { rules } from '@/utils/validation.js'

export default {
  props: { 
    setupData: { 
      type: Object, 
      default: () => {},
    },
  },
  data() {
    return {
      rules,
    };
  },
};
</script>
